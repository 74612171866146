import "@radix-ui/themes/styles.css";
import "./globals.css";
import NProgress from "nprogress";
import { Theme, Flex, Heading, Text } from "@radix-ui/themes";
import type { LoaderFunctionArgs, HeadersFunction } from "@remix-run/node";
import { useEffect, useMemo } from "react";
import { json } from "@remix-run/node";
import {
  Links,
  useRouteLoaderData,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
  useFetchers,
  useNavigation,
} from "@remix-run/react";
import { useSpinDelay } from "spin-delay";
import { ColorSchemeScript, useColorScheme } from "~/lib/color-scheme";
import { parseColorScheme } from "~/lib/color-scheme.server";
import { withSentry, captureRemixErrorBoundaryError } from "@sentry/remix";
import redocScript from "redoc/bundles/redoc.standalone.js?url";

NProgress.configure({ showSpinner: false });

export const headers: HeadersFunction = () => {
  return {
    robots: "noindex",
  };
};

export async function loader({ request }: LoaderFunctionArgs) {
  const colorScheme = await parseColorScheme(request);

  return json(
    {
      colorScheme,
      ENV: {
        SENTRY_DSN: process.env.SENTRY_DSN || "",
        SENTRY_SAMPLE_RATE: process.env.SENTRY_SAMPLE_RATE || 0.2,
        ENVIRONMENT: process.env.ENV,
        AI_COMPLETION_STREAMING_FUNCTION_URL:
          process.env.AI_COMPLETION_STREAMING_FUNCTION_URL,
      },
    },
    {
      headers: {
        Vary: "Cookie",
      },
    },
  );
}

interface DocumentProps {
  title?: string;
  children: React.ReactNode;
}

function Document({ children, title }: DocumentProps) {
  const data = useRouteLoaderData<typeof loader>("root");
  const colorScheme = useColorScheme();
  const navigation = useNavigation();

  const fetchers = useFetchers();

  /**
   * This gets the state of every fetcher active on the app and combine it with
   * the state of the global transition (Link and Form), then use them to
   * determine if the app is idle or if it's loading.
   * Here we consider both loading and submitting as loading.
   */
  let state = useMemo<"idle" | "loading">(
    function getGlobalState() {
      let states = [
        navigation.state,
        ...fetchers.map((fetcher) => fetcher.state),
      ];
      if (states.every((state) => state === "idle")) return "idle";
      return "loading";
    },
    [navigation.state, fetchers],
  );

  const showSpinner = useSpinDelay(state !== "idle", {
    delay: 250,
    minDuration: 200,
  });

  useEffect(() => {
    // and when it's something else it means it's either submitting a form or
    // waiting for the loaders of the next location so we start it
    if (showSpinner) NProgress.start();
    // when the state is idle then we can to complete the progress bar
    if (!showSpinner) NProgress.done();
  }, [showSpinner]);

  return (
    <html lang="en" className={colorScheme}>
      <head>
        <ColorSchemeScript />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {title && <title data-title-override="">{title}</title>}
      </head>
      <body>
        <Theme accentColor="iris" id="root-theme">
          {children}
          <ScrollRestoration />
          {data && (
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
              }}
            />
          )}
          <Scripts />
          <script defer src={redocScript}>
            {" "}
          </script>
        </Theme>
      </body>
    </html>
  );
}

export function App() {
  return (
    <Document>
      <Outlet />
    </Document>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <Document>
        <Flex
          minHeight="200px"
          direction="column"
          gap="3"
          align="center"
          justify="center"
        >
          <Heading size="6">
            {error.status} {error.statusText}
          </Heading>
          <Text>{error.data}</Text>
        </Flex>
      </Document>
    );
  }

  console.error(error);

  return (
    <Document>
      <Flex
        minHeight="200px"
        direction="column"
        gap="3"
        align="center"
        justify="center"
      >
        <Heading size="6">Error</Heading>
        <Text>Something went wrong! Please try again later.</Text>
      </Flex>
    </Document>
  );
}

export default withSentry(App, { wrapWithErrorBoundary: true });
